// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-screenplay-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/components/screenplay.js" /* webpackChunkName: "component---src-components-screenplay-js" */),
  "component---src-components-article-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/components/article.js" /* webpackChunkName: "component---src-components-article-js" */),
  "component---src-pages-about-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-films-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/pages/films.js" /* webpackChunkName: "component---src-pages-films-js" */),
  "component---src-pages-index-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-screenplays-js": () => import("/home/runner/work/lum-portfolio/lum-portfolio/src/pages/screenplays.js" /* webpackChunkName: "component---src-pages-screenplays-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/runner/work/lum-portfolio/lum-portfolio/.cache/data.json")

