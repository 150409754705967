module.exports = [{
      plugin: require('/home/runner/work/lum-portfolio/lum-portfolio/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/home/runner/work/lum-portfolio/lum-portfolio/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/lum-portfolio/lum-portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
